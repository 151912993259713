<template>
    <div class="testimonial-container">
        <vueper-slides 
            :bullets="false"
            :arrows="true"
            class="no-shadow"
            :touchable="true"
            :pauseOnHover="false"
            :fixedHeight="true"
            :speed="7000"
            :parallax="false">
            <vueper-slide
                v-for="(slide, i) in data"
                :key="i"
                class="slide">
                <template v-slot:content>
                    <h2>
                        {{slide.title}}
                    </h2>
                    <p>
                        {{slide.content}}
                    </p>
                    <h3>
                        {{slide.author}}
                    </h3>
                </template>
            </vueper-slide>
        </vueper-slides>
    </div>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'

export default {
    name: "Testimonial",
    components: { VueperSlides, VueperSlide },
    props: ['data'],
}
</script>

<style lang="scss">
.testimonial-container{
    padding: 0 4rem;
    background: #1a1a1a;
}
.slide, .vueperslide--clone{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    h2{
       font-weight: 600;
        text-align: center;
        font-size: 13px;
        letter-spacing: 3px;
        text-transform: uppercase;
        color: #fff;
    }
    p{
        margin: 0 20%;
        color: #fff;
    }
    h3{
        font-weight: 100;
        letter-spacing: 2px;
        color: #fff;
    }
}
.vueperslides--fixed-height { height: 650px; }

@media (max-width: $mobile) { 
    .vueperslides--fixed-height { height: 1150px; }
    .testimonial-container{
        padding: 0;
        background: #1a1a1a;
    }
    .slide, .vueperslide--clone{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        p{
            margin: 0 3rem;
            color: #fff;
        }
        h3{
            font-weight: 100;
            letter-spacing: 2px;
            color: #fff;
        }
    }
    .vueperslides__arrow--prev{
        left: 0;
    }
    .vueperslides__arrow--next{
        right: 0;
    }
}
</style>