<template>
    <div class="info-block" v-bind:style="styleObject" 
        v-bind:class="{ 'small-text': data.smallText, 'header-text': data.headerText }">
        <h3 v-if="data.number" class="number">
            {{data.number}}
        </h3>
        <h3 class="unit" v-bind:class="{'hasNoNumber' : !data.number}" v-html="data.unit">
        </h3>
        <h3 class="description" v-if="data.description" v-html="data.description">
        </h3>
    </div>
</template>

<script>
export default {
    name: "InfoBlock",
    props: ['data'],
    data(){
        return {
            styleObject: {
                backgroundImage: this.data.background ?  `url("${this.data.background}")` : 'url("/images/header.jpg")',
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.info-block{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    max-height: 510px;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    &::before{
        content: ""; // ::before and ::after both require content
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 90vh;
        background-image: linear-gradient(#000, #000);
        opacity: .3;
        max-height: 510px;
    }
    h3{
        color: #fff;
        letter-spacing: 2px;
        z-index: 2;
        padding: 0;
        margin: 0;
    }
    .number{
        font-size: 100px;
        font-weight: 500;
        font-family: "karmina",serif;
        font-style: italic;
        padding-right: 4rem;
        border-right: 1px solid rgba(255,255,255,1);
        margin-bottom: 15px;
    }
    .unit{
        font-weight: 100;
        font-size: 50px;
        padding-left: 4rem;
        text-transform: uppercase;
        &.hasNoNumber{
            padding-left: 0;
        }
    }
    .description{
        color: #fff;
        opacity: 1;
        font-size: 60px;
        line-height: 80px;
        margin: 0 10%;
        font-weight: 100;
        text-align: center;
    }
    &.small-text{
        .unit{
            font-size: 50px;
            line-height: 50px;
        }
    }
    &.header-text{
        .unit{
            font-size: 65px;
            letter-spacing: 2px;
            font-weight: 100;
            color: #fff;
            text-transform: uppercase;
        }
    }
}
@media (max-width: $mobile) { 
    .info-block{
        margin-top: 0;
        height: 100vh;
        flex-direction: column;
        background-size: cover;
        background-attachment: unset;
        background-position: center;
        background-repeat: no-repeat;
        &::before{
            height: 100vh;
        }
        h3{
            color: #fff;
            letter-spacing: 2px;
            z-index: 2;
            padding: 0;
            margin: 0;
        }
        .number{
            padding: 0 3rem;
            padding-bottom: 2rem;
            margin: 0;
            border: none;
            border-bottom: 1px solid rgba(255,255,255,1);
        }
        .unit{
            padding: 0;
            margin: 0;
            padding-top: 2rem;
            text-align: center;
        }
        .description{
            line-height: 40px;
            font-size: 20px;
        }
    }
}
</style>
